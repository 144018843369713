export const PAGE_TITLE = "Orders";
export const PAGE_SINGLE_TITLE = "Order";
export const LINK_URL = "orders";
export const inputFields = {
  order_date: {
    type: "string",
    required: false,
    title: "Order Date",
    inputType: "date",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [
  { name: "Order ID", value: "order_id" },
  { name: "Order Date", value: "order_date", date: true },
  { name: "Is Paid", value: "is_paid", boolean: true },
  { name: "Payment Method", value: "payment_method" },
  { name: "Total Amount", value: "total_amount", preFilled: "₹" },
  { name: "Customer", value: "customer", related: true, field: "name" },
  { name: "Phones", value: "customer", related: true, field: "phone" },
  { name: "Status", value: "status" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "customer.name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "phone",
    field: "customer.phone",
    label: "Phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "email",
    field: "customer.email",
    label: "Email",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  // {
  //   id: "status",
  //   field: "status",
  //   label: "Status",
  //   type: "select",
  //   search_type: "exact",
  //   inputType: "text",
  //   condition: "",
  //   options: [
  //     "PENDING",
  //     "PROCESSING",
  //     "READY TO DISPATCH",
  //     "DELIVERED",
  //     "OUT FOR DELIVERY",
  //     "REFUNDED",
  //     "CANCELLED",
  //     "FAILED",
  //     "RETURNED",
  //   ],
  // },
  {
    id: "payment_method",
    field: "payment_method",
    label: "Payment Method",
    type: "select",
    search_type: "exact",
    inputType: "text",
    condition: "",
    options: ["ONLINE", "COD"],
  },
  {
    id: "is_paid",
    field: "is_paid",
    label: "Is Paid",
    type: "select",
    search_type: "exact",
    inputType: "text",
    condition: "",
    options: ["true", "false"],
  },
];
